import React from 'react'
import styled from 'styled-components'
import '../images/linkedin.svg'
import '../images/github.png'
import '../images/twitter.png'
import '../images/document.png'

const FooterGroup = styled.div`
  padding: 60px 0;
  margin: 0 auto;
  display: grid;
  grid-gap: 10px;

  ul {
    margin: 0 auto;
    padding: 0 20px;
  }

  img {
    width: 24px;
    height: 24px;
  }

  li:hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    transform: translateY(-2px);
  }
`
const Text = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #486791;
  max-width: 500px;
  margin: 0 auto;
`
const Button = styled.button`
  background: linear-gradient(102.24deg, #9b51e0 0%, #3436e7 100%);
  box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
  border-radius: 30px;
  color: white;
  border: none;
  padding: 16px 60px;
  font-weight: 600;
  font-size: 24px;
  justify-self: center;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  &:hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
  }

  a {
    color: white;
  }
`

const Copyright = styled.div`
  color: #486791;
  max-width: 500px;
  margin: 0 auto;
`

const Footer = ({ data, children }) => (
  <FooterGroup>
    <ul>
      <li>
        <a href="https://www.linkedin.com/in/victorjaquez/">
          <img id="icons" src={require('../images/linkedin.svg')} alt="" />
        </a>
      </li>
      <li>
        <a href="http://github.com/victorjaquez">
          <img id="git" src={require('../images/github.png')} alt="" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/victorajaquez">
          <img id="twit" src={require('../images/twitter.png')} alt="" />
        </a>
      </li>
      <li>
        <a href="https://www.dropbox.com/s/sugcxqmh7b8utjy/VictorJaquez_Resume.pdf?dl=0">
          {' '}
          <img id="resume" src={require('../images/document.png')} alt="" />
        </a>
      </li>
    </ul>

    <Text>Let's work together</Text>
    <Button>
      <a href="mailto:victor@jaquez.me">Email</a>
    </Button>

    <Copyright>{children}</Copyright>
  </FooterGroup>
)

export default Footer
